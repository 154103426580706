import { Component, Input } from '@angular/core';
import { UtilityMessageConfig } from '../../models/misc';

@Component({
  selector: 'app-utility-message',
  templateUrl: './utility-message.component.html',
  styleUrls: ['./utility-message.component.scss']
})
export class UtilityMessageComponent {
  @Input() config: UtilityMessageConfig;
}
