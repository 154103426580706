import { EventRequestSingleType } from '../services/event-request/event-request.type';

export class UtilityMessageConfig {
  data: IUtilityMessageData;
  iconClass?: string;
  messageClass?: string;
  titleClass?: string;
  class?: string;
  actions?: IUtilityMessageAction[];
}

export interface IUtilityMessageAction {
  active?: boolean;
  class?: string;
  color: string;
  icon?: string;
  slot?: string;
  text: string;
  onClickCallback?: () => void;
}

export interface IUtilityMessageData {
  icon?: string;
  image?: string;
  imageAlt?: string;
  message?: string;
  title?: string;
  eventRequest?: EventRequestSingleType;
  eventUuid?: string;
  eventRequestUuid?: string;
}

export class ProgressConfig {
  header: string;
  steps: IProgressStep[];
}

export interface IProgressStep {
  type: ProgressStepTypes;
  header: string;
  message: string;
}

export enum ProgressStepTypes {
  COMPLETE = 'COMPLETE',
  CURRENT = 'CURRENT',
  NEXT = 'NEXT'
}

export class StatusConfig {
  name: string;
  icon: string;
  iconSize: string;
  class: string;
  status?: string;
  type?: string;
}

export class IndicatorGroup {
  statuses: string[];
  indicators: StatusConfig[];
}

export class RequestDescription {
  intro?: string;
  main: string;
}

export enum ListTypeEnum {
  QUOTES = 'quotes',
  BOOKED = 'booked',
  INVITED = 'invited',
  INTERESTED = 'interested',
  SUGGESTED = 'suggested'
}

export class EmptyMessageConfig {
  icon?: IEmptyMessageIcon;
  image?: string;
  title?: string;
  message?: string;
  class?: string;
  actions?: IEmptyMessageAction[];
}

export interface IEmptyMessageIcon {
  name: string;
  class?: string;
}

export interface IEmptyMessageAction {
  icon?: string;
  text: string;
  class?: string;
  trigger: EmptyMessageActionTriggers;
}

export enum EmptyMessageActionTriggers {
  NEW_REQUEST = 'new_request'
}
