<div *ngIf="config" id="utility-message" class="tw-flex tw-flex-auto tw-flex-col tw-items-center tw-justify-center tw-p-5" [ngClass]="config?.class">
  <div class="utility-message-icon tw-mb-4" *ngIf="config.data.icon">
    <ate-icon [ngClass]="config?.iconClass" [attr.name]="config?.data?.icon"></ate-icon>
  </div>

  <div class="utility-message-image tw-mb-4" *ngIf="config.data.image">
    <img [src]="config.data.image" [alt]="config?.data?.imageAlt" />
  </div>

  <h2 [ngClass]="config?.titleClass" class="tw-mb-1 tw-text-xl tw-font-bold">
    {{ config?.data?.title }}
  </h2>
  <p [ngClass]="config?.messageClass" class="tw-mb-0 tw-text-slate-600">{{ config?.data?.message }}</p>

  <div class="tw-flex tw-flex-auto tw-flex-row tw-items-center tw-justify-center tw-gap-3" *ngIf="config.actions && config.actions.length">
    <ng-container *ngFor="let action of config.actions">
      <ate-button-control *ngIf="action.active" [slot]="action.slot" [color]="action.color" [class]="action.class" (click)="action.onClickCallback()">
        <ate-icon *ngIf="action.icon" [name]="action.icon"></ate-icon>
        <span>{{ action.text }}</span>
      </ate-button-control>
    </ng-container>
  </div>
</div>
