import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventRequestService } from '../../../../shared/services/event-request/event-request.service';
import { WindowService } from '../../../../shared/services/window/window.service';
import { EventRequestQuotes, EventRequestSingleType } from '../../../../shared/services/event-request/event-request.type';
import { EventOverviewPageConfig } from '../../../../shared/models/event-overview-page-config.interface';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageContextService } from '../../../../shared/services/page-context/page-context.service';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { FormPartialEnum } from '../../../../shared/enums/partial-status.enum';
import { EventRequestOverviewService } from '../../../../shared/services/event-request-overview/event-request-overview.service';
import { filter, first, of, Subject, Subscription, takeUntil } from 'rxjs';
import { EventRequestStatusEnum } from '../../../../shared/services/event-request/event-request-status.enum';
import { Store } from '@ngrx/store';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EventRequestSheetComponent } from '../../../../shared/sheets/event-request/event-request-sheet.component';
import { RoutingFilter } from '../../../../ui/components/filter-bar/models/filter.interface';
import { navigationFilters } from '../../../../app.config';
import { StatusConfig, UtilityMessageConfig } from '../../../../shared/models/misc';
import { appActions } from '../../../../store/actions/app.actions';
import { InvitesList, InvitesListing } from '../../../../shared/models/invites-list.interface';
import { SupplierInvitesService } from '../../../../shared/services/supplier-invites/supplier-invites.service';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DialogComponent } from '../../../../shared/components/dialogs/dialog-base/dialog.component';
import { DialogData, DialogType } from '../../../../shared/models/dialogs';
import { ListingAction } from '../../../suppliers/components/supplier/supplier.component';
import { ListingService } from '../../../../shared/services/listing/listing.service';
import { Listing } from '../../../../shared/services/listing/interfaces';
import { QuoteModel } from '../../../../shared/models/quotes';
import { QuoteStatus } from '../../../../api';
import { EventState } from '../../../../store/reducers/event.reducer';
import { eventActions } from '../../../../store/actions/event.actions';
import { ChangeParentEventModalComponent } from '../../../../shared/standalone/modals/change-parent-event-modal/change-parent-event-modal.component';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { IMainEventData } from 'src/app/shared/models/events';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-event-request-overview',
  templateUrl: './event-request-overview.component.html',
  styleUrls: ['./event-request-overview.component.scss']
})
export class EventRequestOverviewComponent implements OnInit, OnDestroy {
  inviteLimit = 20;
  bookings: EventRequestQuotes[] = [];
  config: EventOverviewPageConfig;
  displayInvites: boolean;
  displayInvitesAwaitingQuotes: boolean;
  emptyNotification: UtilityMessageConfig;
  eventRequest: EventRequestSingleType;
  EventRequestStatusEnum = EventRequestStatusEnum;
  eventRequestUuid: string;
  eventUuid: string;
  grayscaleServiceImage: boolean = false;
  iconColour: string = 'tw-text-slate-50';
  iconName: string = '';
  informationConfig: { title: string; body: string; showNewRequestButton?: boolean };
  innerHeight: number;
  innerWidth: number;
  interested: EventRequestQuotes[] = [];
  invited: EventRequestQuotes[] = [];
  invites: InvitesList;
  invitesData: InvitesListing[] = [];
  isMobile: boolean;
  isSmallMobile: boolean;
  listingData: Listing;
  quoteFilters: RoutingFilter[] = [];
  quotes: EventRequestQuotes[] = [];
  routeOptions: string[] = ['quotes', 'bookings', 'invited', 'interested', 'suggested'];
  routeTitle: string;
  showQuotes: boolean;
  status: StatusConfig;
  statusText: string;
  subInvite$: Subscription;
  subDialog$: Subscription;
  subInvites$: Subscription;
  subPublicListing$: Subscription;
  subParamMap$: Subscription;
  subRouteEvent$: Subscription;
  subRouteData$: Subscription;
  subscriptionList: Subscription = new Subscription();
  suggested: QuoteModel[] = [];
  suggestedSupplierAction: ListingAction;
  viewType: string;
  events: IMainEventData[];
  private _subRequestData$: Subscription;
  private _activeList: string;
  private _closedStatuses: EventRequestStatusEnum[] = [
    EventRequestStatusEnum.CLOSED_BEFORE_APPROVAL,
    EventRequestStatusEnum.CLOSED_EXPIRED_NO_QUOTES,
    EventRequestStatusEnum.CLOSED_NO_MATCHES,
    EventRequestStatusEnum.CLOSED_PASSED,
    EventRequestStatusEnum.CLOSED_REASON_ALL_DECLINED_QUOTES,
    EventRequestStatusEnum.CLOSED_REASON_NO_QUOTES,
    EventRequestStatusEnum.CLOSED_REASON_OPEN_QUOTES,
    EventRequestStatusEnum.DENIED_DUPLICATE,
    EventRequestStatusEnum.DENIED_EMAILS,
    EventRequestStatusEnum.DENIED_FLOOD,
    EventRequestStatusEnum.DENIED_MANUAL,
    EventRequestStatusEnum.EXPIRED_ALL_DECLINED_QUOTES
  ];
  private _destroy$ = new Subject();
  private _openEventRequestStatuses: EventRequestStatusEnum[] = [
    EventRequestStatusEnum.AWAITING_APPROVAL,
    EventRequestStatusEnum.AWAITING_QUOTES,
    EventRequestStatusEnum.FIRST_QUOTE,
    EventRequestStatusEnum.NEW_QUOTE,
    EventRequestStatusEnum.QUOTES_RECEIVED,
    EventRequestStatusEnum.ALL_QUOTES_DECLINED,
    EventRequestStatusEnum.SUPPLIER_CHOSEN_OPEN
  ];

  @ViewChild('requestPicture') requestPicture: ElementRef;

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.isMobile = this._windowService.isMobile();
    this.isSmallMobile = this._windowService.isSmallMobile();
  }

  constructor(
    public eventRequestService: EventRequestService,
    private _windowService: WindowService,
    private _route: ActivatedRoute,
    private _pageContextService: PageContextService,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService,
    private _eventRequestOverviewService: EventRequestOverviewService,
    private _store: Store<EventState>,
    private _router: Router,
    private _bottomSheet: MatBottomSheet,
    private _supplierInvitesService: SupplierInvitesService,
    public dialog: MatDialog,
    private sso: ScrollStrategyOptions,
    private _listingService: ListingService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.isMobile = this._windowService.isMobile();
    if (!this.isMobile) {
      this._store.dispatch(appActions.ToggleShowingSidenav({ payload: true }));
    }
    this.getRoute();
    this.refreshRequestOnFormClose();
  }

  getEvents() {
    this._store
      .select(selectApp.getEvents)
      .pipe(first())
      .subscribe({
        next: events => {
          if (events) {
            this.events = events.filter(
              event => event.mainEventUuid !== this.eventRequest?.event.uuid && event.mainEventDate === this.eventRequest?.event?.startDate
            );
          }
        },
        error: err => {
          console.error('Could not retrieve events from store: ', err);
        }
      });
  }

  /**
   * Set eventRequestUuid & eventUuid from route. Set routeTitle on initial page load
   */
  getRoute() {
    this.subParamMap$ = this._activatedRoute.paramMap.subscribe({
      next: response => {
        this.subscriptionList.add(this.subParamMap$);
        this.eventRequestUuid = response.get('eventRequestUuid');
        this.eventUuid = response.get('eventUuid');
        this.getRequestData(this.eventUuid, this.eventRequestUuid);
      },
      error: err => console.error(err)
    });
  }

  /**
   * Route segment retrieved for redirecting purposes
   */
  getRouteSegments(): string[] {
    const url: string = this._router.url.split('?')[0];
    const segments: string[] = url.split('/').filter(el => el !== '');
    return segments;
  }

  /**
   * Set redirection based on route title provided and data available
   * @param eventRequest
   * @param eventRequestUuid
   * @param hasInvites
   * @param routeSegments
   */
  openEventRequest(eventRequest: EventRequestSingleType, eventRequestUuid: string, hasInvites: boolean, routeSegments: string[]): void {
    let redirectTo: string;

    const showQuotes =
      eventRequest.quotes.length &&
      eventRequest.quotes.some(
        quote => quote.quoteOrganiserStatus !== QuoteStatus.REJECTED_FUTURE && quote.quoteOrganiserStatus !== QuoteStatus.REJECTED_PAST
      );

    const containsAny = this.routeOptions.some(item => routeSegments.includes(item));

    if (!containsAny) {
      if (showQuotes) {
        redirectTo = 'quotes';
      } else {
        if (eventRequest.interests.length) {
          redirectTo = 'interested';
        } else if (eventRequest.bookings.length) {
          redirectTo = 'bookings';
        } else {
          redirectTo = 'suggested';
        }
      }
      this._router.navigateByUrl(`event-request/${eventRequest.event.uuid}/${eventRequestUuid}/${redirectTo}`);
      this.getRequestData(eventRequest.event.uuid, eventRequestUuid);
    }
  }

  /**
   * Get event request data for further processing
   */
  getRequestData(eventUuid: string, eventRequestUuid: string) {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.eventRequestService
      .getRequest(eventUuid, eventRequestUuid)
      .pipe(first())
      .subscribe({
        next: eventRequestSingleType => {
          if (eventRequestSingleType) {
            if (eventRequestSingleType.event.uuid === this.eventUuid) {
              this.handleCurrentEvent(eventRequestSingleType, this.eventRequestUuid);
            }
            this.initForm(eventRequestSingleType);
          }
          this.getEvents();
        },
        error: error => this.handleError(error)
      });
  }

  /**
   * Set eventRequest, quotes, bookings and other variables; get invites data for event request provided; set empty states
   * @param eventRequestSingleType
   * @param eventRequestUuid
   */
  handleCurrentEvent(eventRequestSingleType: EventRequestSingleType, eventRequestUuid: string) {
    this.setIcon(eventRequestSingleType);
    this.grayOutRequestPictureIfClosed(eventRequestSingleType.event.eventOrganiserStatus as EventRequestStatusEnum);
    this.eventRequest = eventRequestSingleType;
    this.getInvites(this.eventRequestUuid, eventRequestSingleType);
    this.processStatus();
    this.setEmptyState();
    this.informationConfig = this._eventRequestOverviewService.getInformationConfig(
      this.eventRequest.event.eventOrganiserStatus as EventRequestStatusEnum
    );
    this._pageContextService.setEventRequestUuid(eventRequestUuid);
    this.quotes = eventRequestSingleType.quotes;
    this.bookings = eventRequestSingleType.bookings;
    this.invited = eventRequestSingleType.invites;
    this.interested = eventRequestSingleType.interests;
    this.checkInterestedState(this.interested, this.eventUuid, this.eventRequestUuid, this.viewType);
    this.viewType = this.setViewType(this._activeList, eventRequestSingleType?.event?.eventOrganiserStatus as EventRequestStatusEnum);

    this._store.dispatch(appActions.DecrementLoadingCount());
  }

  /**
   * Get invites for provided event request
   * @param eventRequestUuid
   * @param eventRequestSingleType
   */
  getInvites(eventRequestUuid: string, eventRequestSingleType: EventRequestSingleType) {
    // Don't return more invites than the remaining limit allows.
    const limit = this.inviteLimit - eventRequestSingleType.liveInviteCount;
    this._store.dispatch(appActions.IncrementLoadingCount());
    (limit > 0 ? this._supplierInvitesService.getInvites(eventRequestUuid, limit) : of([])).pipe(first()).subscribe({
      next: suggested => {
        this.suggested = suggested;

        this.quoteFilters = navigationFilters.eventRequests.map(el => {
          if (el.name.toLowerCase() === 'interested') {
            if (eventRequestSingleType.liveInterestCount === 1) {
              el.tooltip = `${eventRequestSingleType.liveInterestCount} supplier is interested in this request!`;
            } else {
              el.tooltip = `${eventRequestSingleType.liveInterestCount} suppliers are interested in this request!`;
            }
          }
          return el;
        });
        this.setFilterCounters(this.quoteFilters, eventRequestSingleType, this.suggested);
        this.viewType = this.setViewType(this._activeList, eventRequestSingleType?.event?.eventOrganiserStatus as EventRequestStatusEnum);

        this.openEventRequest(eventRequestSingleType, eventRequestUuid, suggested.length > 0, this.getRouteSegments());

        this._store.dispatch(appActions.DecrementLoadingCount());
      },
      error: err => {
        console.error('Invites could not be loaded: ', err);
        this._store.dispatch(appActions.DecrementLoadingCount());
      }
    });
  }

  /**
   * Sets the state of the empty placeholder.
   */
  setEmptyState() {
    // Initially set the config
    this.getEmptyConfig(this._router.url);

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._destroy$)
      )
      .subscribe((navigationEnd: NavigationEnd) => {
        this.getEmptyConfig(navigationEnd.url);
      });
  }

  /**
   * Set filter counters gbased on values provided by event request
   * @param quoteFilters
   * @param eventRequest
   * @param suggested
   */
  setFilterCounters(quoteFilters: RoutingFilter[], eventRequest: EventRequestSingleType, suggested: QuoteModel[]) {
    quoteFilters.forEach(el => {
      switch (el.name.toLowerCase()) {
        case 'quotes':
          el.counter = eventRequest.quoteCount;
          break;
        case 'interested':
          el.counter = eventRequest.liveInterestCount;
          el.show = !!eventRequest.interests.length;
          break;
        case 'invited':
          el.counter = eventRequest.liveInviteCount;
          break;
        case 'booked':
          el.counter = eventRequest.bookingCount;
          break;
        case 'suggested':
          el.counter = suggested && suggested.length ? suggested.length : 0;
          break;
        default:
          break;
      }
    });
  }

  /**
   * Receive selected filter value to set values of activeList
   * @param event
   */
  receiveEmittedFilter(event: RoutingFilter) {
    this._activeList = event.name.toLowerCase();
    this.viewType = this.setViewType(this._activeList, this.eventRequest?.event?.eventOrganiserStatus as EventRequestStatusEnum);
    this.setEmptyState();
    this.checkInterestedState(this.interested, this.eventUuid, this.eventRequestUuid, this.viewType);
  }

  /**
   * Check if interests array has records (live or declined). If empty redirect to Invites
   * @param interested
   * @param eventUuid
   * @param eventRequestUuid
   * @param viewType
   */
  checkInterestedState(interested: EventRequestQuotes[], eventUuid: string, eventRequestUuid: string, viewType: string) {
    if (viewType === 'interested' && !interested.length) {
      this._router.navigateByUrl(`event-request/${eventUuid}/${eventRequestUuid}/invited`);
    }
  }

  /**
   * Set icon type and colour based on the event organiser status and event type
   * @param eventRequest
   */
  private setIcon(eventRequest: EventRequestSingleType) {
    const { colour, name } = this._eventRequestOverviewService.getIconConfig(eventRequest.event.eventType);

    // Grayscale the icon for closed events.
    if (!this._closedStatuses.includes(eventRequest.event.eventOrganiserStatus as EventRequestStatusEnum)) {
      this.iconColour = colour;
    } else {
      this.iconColour = 'tw-text-slate-100';
    }

    this.iconName = name;
  }

  processStatus(): void {
    const statusToProcess: EventRequestStatusEnum = this.eventRequest.event.eventOrganiserStatus as EventRequestStatusEnum;
    const statusObj = this.eventRequestService.processStatus(statusToProcess);
    this.status = this.eventRequestService.setEventRequestData(this.eventRequest.event.eventOrganiserStatus as EventRequestStatusEnum).status;
    this.statusText = statusObj.statusText;
    this.showQuotes = statusObj.showQuotes;
    this.config = this.eventRequestService.getEventOverviewPageConfig(statusToProcess, this.eventRequest.quotes);
  }

  setViewType(activeList: string, requestStatus: EventRequestStatusEnum) {
    let visibility: string;

    const progressStatuses: string[] = [EventRequestStatusEnum.AWAITING_QUOTES, EventRequestStatusEnum.AWAITING_APPROVAL];
    const isAwaiting: boolean = requestStatus && progressStatuses.includes(requestStatus);

    if (this.showQuotes) {
      if (activeList === 'quotes') {
        if (isAwaiting) {
          visibility = 'progress';
        } else if (this.quotes.length) {
          visibility = 'quotes';
        } else if (!this.quotes.length) {
          visibility = 'no-quotes';
        }
      } else if (activeList === 'booked' && this.bookings.length) {
        visibility = 'booked';
      } else if (activeList === 'booked' && !this.bookings.length) {
        visibility = 'no-bookings';
      } else if (activeList === 'invited' && this.invited.length) {
        visibility = 'invited';
      } else if (activeList === 'invited' && !this.invited.length) {
        visibility = 'no-invited';
      } else if (activeList === 'interested' && this.interested.length) {
        visibility = 'interested';
      } else if (activeList === 'interested' && !this.interested.length) {
        visibility = 'no-interested';
      } else if (activeList === 'suggested' && this.suggested.length) {
        visibility = 'suggested';
      } else if (activeList === 'suggested' && !this.suggested.length) {
        visibility = 'no-suggested';
      }
    } else {
      if (isAwaiting) {
        visibility = 'progress';
      }
    }
    return visibility;
  }

  openBottomSheet() {
    this._bottomSheet.open(EventRequestSheetComponent, {
      data: {
        iconName: this.iconName,
        route: this._route,
        config: this.config,
        eventUuid: this.eventRequest.event.uuid,
        eventDate: this.eventRequest.event.startDate,
        title: this.eventRequest.primaryServiceTitle,
        events: this.events
      }
    });
  }

  initForm(eventRequestSingleType: EventRequestSingleType) {
    const { event, formStage, partialFormUuid } = eventRequestSingleType;

    // Only open the supplementary form if the request is open to quotes.
    if (this._openEventRequestStatuses.includes(this.eventRequest?.event?.eventOrganiserStatus as EventRequestStatusEnum)) {
      this.tryLaunchSupplementaryForm(formStage, partialFormUuid, event.uuid);
    }
  }

  refreshRequestOnFormClose() {
    this._enhancedFormsLauncherService
      .onFormClose()
      .pipe(first())
      .subscribe({
        next: () => {
          this.refreshEventDetails();
        },
        error: error => this.handleError(error)
      });
  }

  tryLaunchSupplementaryForm(formStage: FormPartialEnum, partialFormUuid: string, eventUuid: string) {
    const isPartialPending = formStage === FormPartialEnum.PARTIAL_PENDING;
    const hasPartialCompletionFormUuid = !!partialFormUuid;

    if (isPartialPending && hasPartialCompletionFormUuid) {
      const model = this._enhancedFormsLauncherService.getModelFromEvent(this.eventRequest.event);

      this._enhancedFormsLauncherService.launchForm({ formUuid: partialFormUuid, eventUuid, model });
    }
  }

  openEventRequestDetails(): void {
    this._router.navigate(['details'], { relativeTo: this._route });
  }

  openCloseEventRequest(): void {
    this._router.navigate([{ outlets: { modalOutlet: ['close'] } }], { relativeTo: this._route });
  }

  goBack(): void {
    this._router.navigate(['/']);
  }

  goToQuote(quote: EventRequestQuotes): void {
    this._router.navigate(['/quote/', quote.uuid]);
  }

  /**
   * Get public listing for listing uuid provided
   * @param listing
   * @param listType
   */
  getPublicListing(listing, listType: string) {
    let listingUuid: string;

    switch (listType) {
      case 'suggested':
        listingUuid = listing.uuid;
        break;
      case 'interested':
      case 'invited':
        listingUuid = listing.listingUuid;
        break;
    }

    this.subPublicListing$ = this._listingService.getPublicListing(listingUuid).subscribe({
      next: response => {
        this.subscriptionList.add(this.subPublicListing$);
        if (response) {
          this.listingData = response;
          switch (listType) {
            case 'suggested':
              this.openSuggestedDialog(response);
              break;
            case 'invited':
              this.openInvitedDialog(response);
              break;
            case 'interested':
              this.openInterestedDialog(response);
              break;
          }
        }
        return response;
      },
      error: err => {
        console.error('Public listing error: ', err);
      }
    });
  }

  /**
   * Open Suggested dialog and set action type after close
   * @param suggestedSupplier
   */
  openSuggestedDialog(suggestedSupplier: Listing) {
    const dialogData: DialogData = {
      type: DialogType.SUGGESTED,
      title: 'Suggested',
      showHeader: false,
      record: {
        inviteData: suggestedSupplier,
        inviteHeader: {
          icon: 'ate-info-v2',
          intro: `This is a suggested supplier that matches your event!`,
          main: ` — do you want to invite them to send a quote`
        }
      }
    };

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '1000px',
      height: this.innerWidth > 1023 ? 'auto' : '99vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'invite-dialog',
      scrollStrategy: this.sso.noop(),
      data: dialogData
    });

    this.subDialog$ = dialogRef.afterClosed().subscribe({
      next: response => {
        this.subscriptionList.add(this.subDialog$);
        if (response) {
          this.suggestedSupplierAction = response;
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }

  /**
   * Open Interested dialog and set action type after close
   * @param interested
   */
  openInterestedDialog(interested: Listing) {
    const dialogData: DialogData = {
      type: DialogType.INTERESTED,
      title: 'Interested',
      showHeader: false,
      record: {
        inviteData: interested,
        inviteHeader: {
          icon: 'ate-hearts-inverted',
          intro: `This supplier is interested in your event!`,
          main: ` — Invite them to send a quote`
        }
      }
    };

    const dialogRef = this.dialog.open(DialogComponent /* the wrapper dialog component */, {
      width: '1000px',
      height: this.innerWidth > 1023 ? 'auto' : '99vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'invite-dialog',
      scrollStrategy: this.sso.noop(),
      data: dialogData
    });

    this.subDialog$ = dialogRef.afterClosed().subscribe({
      next: response => {
        this.subscriptionList.add(this.subDialog$);
        if (response) {
          this.suggestedSupplierAction = response;
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }

  /**
   * Open Invited dialog
   * @param listing
   */
  openInvitedDialog(listing: Listing) {
    const dialogData: DialogData = {
      type: DialogType.INVITED,
      title: 'Invited',
      showHeader: false,
      record: {
        inviteData: listing,
        inviteHeader: {
          icon: 'ate-hearts',
          intro: `You invited this supplier to send a quote`,
          main: ` —  awaiting their response`
        }
      }
    };

    const dialogRef = this.dialog.open(DialogComponent /* the wrapper dialog component */, {
      width: '1000px',
      height: this.innerWidth > 1023 ? 'auto' : '100vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'invite-dialog',
      scrollStrategy: this.sso.noop(),
      data: dialogData
    });

    this.subDialog$ = dialogRef.afterClosed().subscribe({
      next: () => {
        this.subscriptionList.add(this.subDialog$);
      },
      error: err => {
        console.error(err);
      }
    });
  }

  refreshEventDetails() {
    const { paramMap } = this._route.snapshot;
    this._store.dispatch(
      eventActions.GetEvent({
        payload: {
          eventUuid: paramMap.get('eventUuid'),
          eventRequestUuid: paramMap.get('eventRequestUuid')
        }
      })
    );
  }

  private handleError(error) {
    console.error(error);
    this._store.dispatch(appActions.DecrementLoadingCount());
  }

  /**
   * Closed events should show a grayscale version of the image.
   * @param eventRequestStatus The status of the event
   */
  private grayOutRequestPictureIfClosed(eventRequestStatus: EventRequestStatusEnum) {
    this.grayscaleServiceImage = this._closedStatuses.includes(eventRequestStatus);
  }

  private getEmptyConfig(url) {
    if (url.includes('quotes')) {
      this._activeList = 'quotes';
      this.emptyNotification = {
        data: {
          title: 'Your quotes from suppliers will live here',
          message: 'The personalised quotes you receive from suppliers for your service request will be shown here.',
          icon: 'waiting-for-quotes-v3'
        },
        class: 'empty-state-message'
      };
    } else if (url.includes('bookings')) {
      this._activeList = 'booked';
      this.emptyNotification = {
        data: {
          title: 'Your booked suppliers will live here',
          message: 'The supplier(s) you choose for your service request will be shown here.',
          icon: 'handshake'
        },
        class: 'empty-state-message'
      };
    } else if (url.includes('invited')) {
      this._activeList = 'invited';
      this.emptyNotification = {
        data: {
          title:
            this.displayInvites || this.displayInvitesAwaitingQuotes
              ? 'You haven’t invited any matched suppliers yet'
              : 'Invited suppliers will live here',
          message:
            this.displayInvites || this.displayInvitesAwaitingQuotes
              ? 'Do you want to receive a 30% quicker response from suppliers? Get the ball rolling by inviting a selection of your matched suppliers to send you a quote.'
              : 'The supplier(s) you invited will be shown here.',
          image: '../assets/images/supplier-invites-intro-image-medium.png',
          imageAlt: 'No invited',
          eventRequest: this.eventRequest,
          eventUuid: this.eventUuid,
          eventRequestUuid: this.eventRequestUuid
        },
        class: 'empty-state-message',
        actions: [
          {
            active: this.displayInvites || this.displayInvitesAwaitingQuotes,
            class: 'tw-gap-x-2 tw-mt-8',
            color: 'cta',
            icon: 'plus',
            text: 'Invite Suppliers to Quote',
            onClickCallback: () => {
              window.location.href = `${environment.webUrl}/request/${this.eventRequestUuid}/matches`;
            }
          }
        ]
      };
    } else if (url.includes('interested')) {
      this._activeList = 'interested';
      this.emptyNotification = {
        data: {
          title: 'Interested suppliers will live here',
          message: 'Suppliers that are interested in your service request will be shown here.',
          icon: 'matching'
        },
        class: 'empty-state-message'
      };
    } else if (url.includes('suggested')) {
      this._activeList = 'suggested';
      if (this.inviteLimit - this.eventRequest.liveInviteCount < 1) {
        this.emptyNotification = {
          data: {
            title: `You've invited ${this.inviteLimit} suppliers to quote!`,
            message: `You've reached the maximum number of supplier invites allowed for a request. \nView your invited suppliers in the invited tab.`,
            icon: 'ate-invites-sent',
            eventUuid: this.eventUuid,
            eventRequestUuid: this.eventRequestUuid
          },
          actions: [
            {
              active: true,
              class: 'tw-gap-x-2 tw-mt-8',
              color: 'cta',
              text: 'View invited suppliers',
              onClickCallback: () => {
                const invitesFilter = this.quoteFilters.find(quoteFilter => quoteFilter.name === 'Invited');
                this.receiveEmittedFilter(invitesFilter);
                this._router.navigateByUrl(`event-request/${this.eventUuid}/${this.eventRequestUuid}/invited`);
              }
            }
          ],
          class: 'empty-state-message'
        };
      } else {
        this.emptyNotification = {
          data: {
            title: 'No suggested suppliers available',
            message: "Sorry, we don't have any more suppliers to suggest for this request.",
            icon: 'ate-no-search-results'
          },
          class: 'empty-state-message'
        };
      }
    }
  }

  /**
   * Refresh request data on action triggered by a card (invite, skip, decline interest)
   */
  receiveListingUpdate() {
    this.getRequestData(this.eventUuid, this.eventRequestUuid);
  }

  openChangeParentEventModal() {
    this.dialog.open(ChangeParentEventModalComponent, {
      panelClass: 'change-parent-event-dialog',
      width: '488px',
      maxWidth: '100vw',
      data: {
        eventUuid: this.eventRequest.event.uuid,
        eventDate: this.eventRequest.event.startDate,
        title: this.eventRequest.primaryServiceTitle,
        events: this.events
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
